import $ from 'jquery';
window.$ = window.jQuery = $;
import 'slick-carousel';



export function slickInit() {
    jQuery(document).ready(function ($) {
        $(".section-slickcarousel__wrapper").slick({
            infinite: true,
            autoplay: true,
            autoplaySpeed: 5000,
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            arrows: true,
        });
    });

    jQuery(document).ready(function ($) {
        $(".section-projects__photosSlider").slick({
            infinite: true,
            autoplay: true,
            autoplaySpeed: 5000,
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            arrows: true,
        });
    });
}