import {navInit} from './scripts/navigation';
import {slickInit} from './scripts/slickInit';
import {mapInit} from './scripts/map';

mapInit();

navInit();
slickInit();
jQuery(document).ready(function ( $ ) {

  // smooth scroll to ID
  $('.menu-item a[href*="#"]').on('click', function (e) {
    e.preventDefault();
    let url;
    if( $('body').hasClass('home') ) {
      url = $(this).attr('href').split('/').pop();
      $('html, body').animate({
        scrollTop: $(url).offset().top - 0 // choose what you need
      }, 500, 'linear');
    } else {
      url = $(this).attr('href');
      console.log(url[0]);
      if( url[0] == '/' ) {
        document.location.href = url;
      } else {
        $('html, body').animate({
          scrollTop: $(url).offset().top - 0 // choose what you need
        }, 500, 'linear');
      }
    }
  });

  // const navbar = $('#header__navbar')
  // const nav = $('.header_nav');
  // $(window).scroll(function() {    
  //   let scroll = $(window).scrollTop();
  //   if (scroll >= 50) {
  //     navbar.addClass("scrolled");
  //     nav.addClass("scrolled");
  //   } else {
  //     navbar.removeClass("scrolled");
  //     nav.removeClass("scrolled");
  //   }
  // });

});